<template>

  <div class="col">

    <!-- Large Screens  -->
    <server-table
      :count="count"
      :DataItems="usersData"
      :filter="filter"
      @Refresh="Refresh"
      @selection="selection"
      @reset="filter = {}"
      @export="Export"
      v-loading="$store.getters['user/usersLoad']"
      :title="$t('users')"
    >
      <template #columns>
        <el-table-column
          type="selection"
          width="60"
          prop="selection"
        ></el-table-column>

        <el-table-column prop="id" sortable label="#" min-width="60">
        </el-table-column>

        <el-table-column prop="name" sortable :label="$t('Global.name')" min-width="120">
        </el-table-column>

        <el-table-column prop="email" :label="$t('Global.email')" min-width="180">
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
              "
            >
              {{ scope.row.email }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="gender" :label="$t('Global.gender')" sortable min-width="100">
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
              "
            >
              {{
                scope.row.gender
                  ? scope.row.gender == 1
                    ? 'ذكر'
                    : 'انثى'
                  : "_"
              }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="birthdate" :label="$t('Global.birthdate')" min-width="180">
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
              "
            >
              {{
                scope.row.birthdate ? toLocalDatetime(scope.row.birthdate) : "_"
              }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="type" :label="$t('type')" min-width="100" sortable>
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
              "
            >
              {{
                scope.row.type != null
                  ? scope.row.type == 1
                    ? 'طالب'
                    : 'معلم'
                  : "_"
              }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="" label="_" width="180">
          <template slot-scope="scope">
            <div class="td-actions">
              <!-- View Profile  -->
              <router-link
                v-tooltip.top-center="$t('view_user_profile')"
                class="btn btn-info btn-link btn-xs px-2"
                :to="`/user-profile/${scope.row.id}`"
              >
                <i class="fa fa-user" style="font-size: 17px"></i>
              </router-link>
              <!-- Edit user  -->
              <el-button
                class="border-0 px-2"
                v-tooltip.top-center="$t('edit_user_data')"
                @click="open_edit_dialog(scope.row)"
                ><i class="fa fa-edit text-warning"></i>
              </el-button>
              <!-- View roles  -->
              <el-button
                v-tooltip.top-center="$t('show_user_role')"
                class="border-0 px-2"
                @click="$router.push(`/permissions/${scope.row.id}`)"
              >
                <i class="fa fa-lock text-secondary"></i>
              </el-button>
              <!-- Delete or Reactivate User  -->
              <a
                v-tooltip.top-center="$t('delete_user')"
                @click="remove(scope.row.id)"
                class="btn btn-danger btn-link btn-xs px-2"
              >
                <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </template>

      <template #filter>
        <el-row :gutter="20">
          <el-col :md="12"
            ><el-select
              v-model="filter.gender"
             :placeholder="$t('Global.gender')"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(gender, i) in genders"
                :key="i"
                :label="gender.txt"
                :value="gender.val"
                class="text-right mr-3"
              ></el-option> </el-select
          ></el-col>
          <el-col :md="12"
            ><el-select
              v-model="filter.type"
            :placeholder="$t('type')"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(type, i) in types"
                :key="i"
                :label="type.txt"
                :value="type.val"
                class="text-right mr-3"
              ></el-option> </el-select
          ></el-col>
        </el-row>
      </template>
      <template #action>
        <button
          @click="send_mail_dialog = true"
          class="border-0 text-dark"
          style="background: transparent"
          :disabled="mail.users.length == 0"
        >
          <el-icon class="el-icon-plus"></el-icon>
       {{$t("send_mail")}}
        </button>
      </template>
    </server-table>

    <!-- Edit User Dialog  -->
    <el-dialog
      :title="$t('edit_user_data')"
      width="70%"
      :visible.sync="edit_user_dialog"
      top="4vh"
      class="text-right"
    >
      <el-form
        v-loading="$store.getters['user/usersLoad']"
        :model="editUser"
        ref="editedUserRef"
        :rules="editedUserRules"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="24" :md="12">
            <el-form-item :label="$t('Global.email')" prop="email">
              <el-input
                v-model="editUser.email"
                style="width: 100%"
                :placeholder="$t('Global.email')"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24" :md="12">
            <el-form-item :label="$t('Global.name')" prop="name">
              <el-input
                v-model="editUser.name"
                style="width: 100%"
                :placeholder="$t('Global.name')"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24" :md="12">
            <el-form-item :label="$t('Global.job_title')" prop="job_title">
              <el-input
                v-model="editUser.job_title"
                style="width: 100%"
                :placeholder="$t('Global.job_title')"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24" :md="12">
            <el-form-item :label="$t('Global.country')"  prop="country">
              <el-input
                v-model="editUser.country"
                style="width: 100%"
               :placeholder="$t('Global.country')"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24" :md="12">
            <el-form-item :label="$t('Global.gender')"  prop="gender">
              <el-radio-group v-model="editUser.gender">
                <el-radio :label="0">{{$t("Global.gender_1")}}</el-radio>
                <el-radio :label="1">{{$t("Global.gender_2")}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span="24" :md="12">
            <el-form-item :label="$t('type')" prop="type">
              <el-radio-group v-model="editUser.type">
                <el-radio :label="0">{{$t("Global.type_0")}}</el-radio>
                <el-radio :label="1">{{$t("Global.type_1")}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item :label="$t('Global.birthdate')" prop="birthdate">
              <el-date-picker
                v-model="editUser.birthdate"
                type="date"
                :placeholder="$t('Global.birthdate')"
                :picker-options="datePickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item :label="$t('Global.my_info')">
              <el-input type="textarea" v-model="editUser.about_me"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        class="
          dialog-footer
          w-50
          flex-column flex-lg-row
          d-md-flex
          my-3
          mr-auto
        "
        dir="ltr"
      >
        <el-button
          class="text-center ml-lg-2 mx-auto d-block w-100 my-1"
          @click="edit_user_dialog = false"
          >{{$t("cancel")}}
        </el-button>
        <el-button
          class="
            text-center
            px-4 px-lg-3
            ml-lg-2
            mx-auto
            d-block
            text-capitalize
            w-100
            my-1
          "
          type="primary"
          @click="save"
          > {{$t("save")}}</el-button
        >
      </span>
    </el-dialog>

    <!-- send mail Dialog  -->
    <el-dialog
      :title="$t('send_mail')"
      width="70%"
      :visible.sync="send_mail_dialog"
      top="4vh"
      class="text-right"
    >
      <el-form
        :model="mail"
        ref="mailRef"
        :rules="mailsRules"
        label-position="top"
        v-loading="$store.getters['user/usersLoad']"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item :label="$t('mail_content')" prop="content">
              <el-input type="textarea" v-model="mail.content"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        class="
          dialog-footer
          w-50
          flex-column flex-lg-row
          d-md-flex
          my-3
          mr-auto
        "
        dir="ltr"
      >
        <el-button
          class="text-center ml-lg-2 mx-auto d-block w-100 my-1"
          @click="send_mail_dialog = false"
          >{{$t('cancel')}}
        </el-button>
        <el-button
          class="
            text-center
            px-4 px-lg-3
            ml-lg-2
            mx-auto
            d-block
            text-capitalize
            w-100
            my-1
          "
          type="primary"
          @click="sendMail"
          >{{$t('send')}}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import Swal from "sweetalert2";

export default {
  name: "index",
  components: { ServerTable },
  data() {
    return {
      // filter data
      filter: {},
      genders: [
        { val: 1, txt: this.$t('Global.gender_1') },
        { val: 2, txt: this.$t('Global.gender_2') },
      ],
      // values are text because type value will not be send as a parameter of the query with the request, so it has to be text here only not else where
      types: [
        { val: "0", txt:this.$t('Global.type_0') },
        { val: "1", txt: this.$t('Global.type_1') },
      ],

      // edit user dialog data
      editUser: {},
      edit_user_dialog: false,

      editedUserRules: {
        name: [
          {
            required: true,
            message: this.$t("name_required"),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message:this.$t("email_required"),
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: this.$t("country_required"),
            trigger: "blur",
          },
        ],
        gender: [
          {
            required: true,
            message:this.$t("gender_required"),
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: this.$t("type_required"),
            trigger: "change",
          },
        ],
        job_title: [
          {
            required: true,
            message: this.$t("job_title_required"),
            trigger: "change",
          },
        ],
        birthdate: [
          {
            required: true,
            message: this.$t("birthdate_required"),
            trigger: "change",
          },
        ],
      },
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },

      // send email data
      send_mail_dialog: false,
      mail: { users: [], content: "" },
      mailsRules: {
        content: [
          {
            required: true,
            message:  this.$t("mail_content_required"),
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    usersData() {
      return this.$store.getters["user/users"];
    },
    count() {
      return this.$store.getters["user/totalUsers"];
    },
    id() {
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
  },
  methods: {
    Refresh(query) {
      // console.log(query);
      this.$store.dispatch("user/users", { query: query });
    },

    remove(id) {
      Swal.fire({
        title: this.$t('confirm_delete_user'),
        text: this.$t('ask_delete_user'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store.dispatch("user/delete", id).then((_) => {
            this.Refresh(null);
            Swal.fire({
              title:this.$t('deleted'),
              text: this.$t('user_deleted'),
              icon: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          });
        }
      });
    },
    selection(selections) {
      // console.log(selections);
      this.mail.users = [...selections].map((el) => el.id);
    },
    Export(query) {
      this.$store.dispatch("user/export", { query: query });
    },

    // send email functions
    sendMail() {
      this.$refs["mailRef"].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("user/send_mail", { query: this.mail })
            .then(() => {
              this.send_mail_dialog = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    open_edit_dialog(item) {
      this.editUser = { ...item };
      this.edit_user_dialog = true;
    },
    save(id) {
      this.$refs["editedUserRef"].validate((valid) => {
        if (valid) {
          this.$store.dispatch("user/save", { id: id, query: this.editUser });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
::v-deep .cell {
  text-align: right;
  word-break: break-word;
}

::v-deep .el-form--label-top .el-form-item__label {
  position: relative;
}

::v-deep .el-form-item__label:before {
  position: absolute;
  left: -12px;
}

::v-deep .el-dialog__headerbtn {
  left: 20px;
  right: unset;
}

::v-deep .el-form-item__error {
  left: unset;
  right: 0;
  font-size: 0.9rem;
}

::v-deep .el-radio {
  margin-left: 30px;
  margin-right: 0;
}

::v-deep .el-radio__input {
  margin-left: 10px;
}
</style>
